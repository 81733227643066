import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press 10×10\\@55%1RM with 1:00 rest between sets`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time:`}</p>
    <p>{`14-DB Shoulder Press (35’s/25’s)(RX+ 50’s/35’s)`}</p>
    <p>{`16-Ring Rows`}</p>
    <p>{`18-GHD Situps`}</p>
    <p>{`20-Calorie Row`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      